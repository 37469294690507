<template>
  <div class="text-4xl font-semibold mt-64">是不是可以换个时间看？</div>
  <div class="text-2xl font-semibold mt-8">要休息的话，可以换个方式休息，如果没有明确的目的，就不要看这个网站</div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String,
  },
}
</script>
